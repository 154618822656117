import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
	ADD_NEW_HOLIDAYREQUEST,
	DELETE_HOLIDAYREQUEST,
	HOLIDAYREQUEST_FORM_TOOGLE_LOADING,
	SET_HOLIDAYREQUEST_LIST,
	SHOW_NOTIFICATION,
	UPDATE_HOLIDAYREQUEST,
} from "../constants";
import { formatHolidayRequests } from "./settingsActionsUtils";

/* HOLIDAYREQUEST LIST */
export const fetchHolidayRequests = () => {
	return async (dispatch) => {
		const response = await axios
			.get(API.holidayRequests.list)
			.then((response) => {
				const holidayRequests = formatHolidayRequests(response.data);
				dispatch({
					type: SET_HOLIDAYREQUEST_LIST,
					payload: keyBy(holidayRequests, "_id"),
				});
				response.data = keyBy(holidayRequests, "_id");
				return response;
			})
			.catch((err) => err);
		return response;
	};
};

// HOLIDAYREQUESTS
export const createHolidayRequest = (newHolidayRequest) => {
	return async (dispatch) => {
		dispatch({ type: HOLIDAYREQUEST_FORM_TOOGLE_LOADING });
		const response = await axios
			.post(API.holidayRequests.create, newHolidayRequest)
			.then((response) => {
				const holidayRequest = formatHolidayRequests(response.data);
				dispatch({ type: ADD_NEW_HOLIDAYREQUEST, payload: holidayRequest });
				dispatch({ type: HOLIDAYREQUEST_FORM_TOOGLE_LOADING });
				dispatch({
					type: SHOW_NOTIFICATION,
					payload: {
						show: true,
						status: "success",
						message: "Petición creada con éxito.",
					},
				});
				return response?.status;
			})
			.catch((err) => {
				dispatch({ type: HOLIDAYREQUEST_FORM_TOOGLE_LOADING });
				return err;
			});
		return response;
	};
};

export const updateHolidayRequest = (updatedHolidayRequest) => {
	return async (dispatch) => {
		dispatch({ type: HOLIDAYREQUEST_FORM_TOOGLE_LOADING });
		const response = await axios
			.put(`${API.holidayRequests.edit}${updatedHolidayRequest?._id}`, updatedHolidayRequest)
			.then((response) => {
				const holidayRequest = formatHolidayRequests(response.data);
				dispatch({ type: UPDATE_HOLIDAYREQUEST, payload: holidayRequest });
				dispatch({ type: HOLIDAYREQUEST_FORM_TOOGLE_LOADING });
				dispatch({
					type: SHOW_NOTIFICATION,
					payload: {
						show: true,
						status: "info",
						message: "Petición actualizada.",
					},
				});
				return response?.status;
			})
			.catch((err) => {
				dispatch({ type: HOLIDAYREQUEST_FORM_TOOGLE_LOADING });
				return err;
			});
		return response;
	};
};

export const deleteHolidayRequests = (Ids) => {
	return async (dispatch) => {
		dispatch({ type: HOLIDAYREQUEST_FORM_TOOGLE_LOADING });
		const response = await axios
			.delete(`${API.holidayRequests.delete}`, { data: Ids })
			.then((response) => {
				dispatch({ type: DELETE_HOLIDAYREQUEST, payload: Ids });
				dispatch({ type: HOLIDAYREQUEST_FORM_TOOGLE_LOADING });
				dispatch({
					type: SHOW_NOTIFICATION,
					payload: {
						show: true,
						status: "info",
						message: "Petición eliminada.",
					},
				});
				return response?.status;
			})
			.catch((err) => {
				dispatch({ type: HOLIDAYREQUEST_FORM_TOOGLE_LOADING });
				return err;
			});
		return response;
	};
};

export const fetchPublicHolidays = async (year) => {
	try {
		// Obtenemos los festivos de España
		const response = await axios.get(`https://date.nager.at/api/v3/PublicHolidays/${year}/ES`);

		// Filtramos los festivos relevantes para Palma de Mallorca
		const palmaHolidays = response.data.filter((holiday) => {
			// Excluir el festivo del 24 de junio
			if (holiday.date.slice(5) === "06-24") return false;

			// Festivos nacionales
			if (holiday.global) return true;

			// Festivos específicos de las Islas Baleares
			if (holiday.counties && holiday.counties.includes("ES-IB")) return true;

			// Festivos específicos de Palma de Mallorca
			const palmaSpecificHolidays = [
				"01-20", // San Sebastián (patrón de Palma)
				"04-18", // Lunes de Pascua (fecha puede variar, ajustar según el año)
				"12-26", // Segunda fiesta de Navidad
				"12-27", // Nuevo festivo: 27 de diciembre
				"12-31", // San Silvestre (Nochevieja)
			];

			return palmaSpecificHolidays.includes(holiday.date.slice(5));
		});

		const updatedHolidays = palmaHolidays.concat([
			{
				date: "2024-12-27",
				localName: "Fiesta 1 de julio Ecoquimic",
				name: "Fiesta 1 de julio Ecoquimic",
				type: "National",
			},
			{
				date: "2025-01-20",
				localName: "Festivo Ecoquimic",
				name: "Festivo Ecoquimic",
				type: "National",
			},
		]);

		return updatedHolidays.map((holiday) => ({
			date: holiday.date,
			localName: holiday.localName,
			name: holiday.name,
			type: holiday.type,
		}));
	} catch (error) {
		console.error("Error fetching Palma holidays:", error);
		return [];
	}
};
