import { handleAction, handleActions } from "redux-actions";
import { createCrudReducers } from "../../utils/crudGenerator";
import {
  AUTH,
  CLOSE_ESTABLISHMENTINFO_FORM,
  CLOSE_NOTIFICATION,
  CLOSE_SIGN_FORM,
  EDIT_SELECTED_ESTABLISHMENTINFO,
  ESTABLISHMENTINFO_FORM_TOOGLE_LOADING,
  LOGOUT,
  OPEN_ESTABLISHMENTINFO_FORM,
  OPEN_SIGN_FORM,
  SET_ALLEQUIPMENT_LIST,
  SET_ALLINSTALATION_LIST,
  SET_ARTICLES_LIST,
  SET_CURRENT_ROUTE,
  SET_DELIVERYNOTES_LIST,
  SET_DOWNLOADDOCUMENT_LIST,
  SET_ESTABLISHMENTINFO,
  SET_INVOICES_LIST,
  SET_NAVUSER_LIST,
  SET_PRODUCTS_LIST,
  SET_PROFILE,
  SET_PROJECTSNAV_LIST,
  SET_PROVIDERS_LIST,
  SET_REPARTIDORS_LIST,
  SET_REPARTIMENTS_LIST,
  SET_REPCON_LIST,
  SET_SIDE_MENU_STATE,
  SHOW_NOTIFICATION,
  THEME
} from "./../../constants";

/* BASIC */
export const authorization = handleActions(
  {
    [AUTH]: (state, action) => action.payload,
    [LOGOUT]: () => ({ auth: false }),
  },
  null
);

/* PROFILE */
export const profile = handleActions(
  {
    [SET_PROFILE]: (state, action) => action.payload,
  },
  null
);

/* APP */
export const currentRoute = handleAction(
  SET_CURRENT_ROUTE,
  (state, action) => action.payload,
  null
);

export const notifications = handleActions(
  {
    [SHOW_NOTIFICATION]: (state, action) => action.payload,
    [CLOSE_NOTIFICATION]: (state, action) => ({
      show: false,
      status: null,
      message: null,
    }),
  },
  { show: false, status: null, message: null }
);

/* APP */
export const app = handleActions(
  {
    [SET_SIDE_MENU_STATE]: (state, action) => {
      return { ...state, isCollapsedSideMenu: action.payload };
    },
  },
  { isCollapsedSideMenu: true }
);

export const currentTheme = handleAction(
  THEME,
  (state, action) => action.payload,
  false
);

export const navUsers = handleActions(
  {
    [SET_NAVUSER_LIST]: (state, action) => action.payload,
  },
  null
);

export const providers = handleActions(
  {
    [SET_PROVIDERS_LIST]: (state, action) => action.payload,
  },
  null
);


export const products = handleActions(
  {
    [SET_PRODUCTS_LIST]: (state, action) => action.payload,
  },
  null
);

export const repcon = handleActions(
  {
    [SET_REPCON_LIST]: (state, action) => action.payload,
  },
  null
);

export const projectsnav = handleActions(
  {
    [SET_PROJECTSNAV_LIST]: (state, action) => action.payload,
  },
  null
);

// DOWNLOADDOCUMENT
export const downloadDocuments = handleActions(
  {
    [SET_DOWNLOADDOCUMENT_LIST]: (state, action) => action.payload,
  },
  null
);

export const signForm = handleActions(
  {
    [OPEN_SIGN_FORM]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [CLOSE_SIGN_FORM]: (s, action) => ({
      show: false,
      data: null,
      loading: false,
    }),
  },
  { show: false, data: null, loading: false }
);

// ARTICLES
export const articles = handleActions(
  {
    [SET_ARTICLES_LIST]: (state, action) => action.payload,
  },
  null
);

// REPARTIDORS
export const repartidors = handleActions(
  {
    [SET_REPARTIDORS_LIST]: (state, action) => action.payload,
  },
  null
);

// REPARTIMENTS
export const repartiments = handleActions(
  {
    [SET_REPARTIMENTS_LIST]: (state, action) => action.payload,
  },
  null
);

// INVOICES
export const invoices = handleActions(
  {
    [SET_INVOICES_LIST]: (state, action) => action.payload,
  },
  null
);

// DELIVERYNOTES
export const deliveryNotes = handleActions(
  {
    [SET_DELIVERYNOTES_LIST]: (state, action) => action.payload,
  },
  null
);

export const allInstalations = handleActions(
  {
    [SET_ALLINSTALATION_LIST]: (state, action) => action.payload,
  },
  null
);

export const allEquipments = handleActions(
  {
    [SET_ALLEQUIPMENT_LIST]: (state, action) => action.payload,
  },
  null
);

// ESTABLISHMENTINFOS
export const establishmentInfo = handleActions(
  {
    [SET_ESTABLISHMENTINFO]: (state, action) => action.payload,
  },
  null
);

export const establishmentInfoForm = handleActions(
  {
    [OPEN_ESTABLISHMENTINFO_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_ESTABLISHMENTINFO_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_ESTABLISHMENTINFO]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [ESTABLISHMENTINFO_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

const workShiftReducers = createCrudReducers('WORKSHIFT');
export const workShifts = workShiftReducers.list;
export const workShiftsForm = workShiftReducers.form;

const userReducers = createCrudReducers('USER');
export const users = userReducers.list;
export const usersForm = userReducers.form;

const equipmentConfigurationReducers = createCrudReducers('EQUIPMENTCONFIGURATION');
export const equipmentConfigurations = equipmentConfigurationReducers.list;
export const equipmentConfigurationsForm = equipmentConfigurationReducers.form;

const instalationReducers = createCrudReducers('INSTALATION');
export const instalations = instalationReducers.list;
export const instalationsForm = instalationReducers.form;

const securitySheetReducers = createCrudReducers('SECURITYSHEET');
export const securitySheets = securitySheetReducers.list;
export const securitySheetsForm = securitySheetReducers.form;

const shopCartReducers = createCrudReducers('SHOPCART');
export const shopCarts = shopCartReducers.list;
export const shopCartsForm = shopCartReducers.form;

const satisfactionReducers = createCrudReducers('SATISFACTION');
export const satisfactions = satisfactionReducers.list;
export const satisfactionsForm = satisfactionReducers.form;

const incidenceReducers = createCrudReducers('INCIDENCE');
export const incidences = incidenceReducers.list;
export const incidencesForm = incidenceReducers.form;

const equipmentReducers = createCrudReducers('EQUIPMENT');
export const equipments = equipmentReducers.list;
export const equipmentsForm = equipmentReducers.form;

const singUpReducers = createCrudReducers('SINGUP');
export const singUps = singUpReducers.list;
export const singUpsForm = singUpReducers.form;

const projectReducers = createCrudReducers('PROJECT');
export const projects = projectReducers.list;
export const projectsForm = projectReducers.form;

const suggestionReducers = createCrudReducers('SUGGESTION');
export const suggestions = suggestionReducers.list;
export const suggestionsForm = suggestionReducers.form;

const workOrderReducers = createCrudReducers('WORKORDER');
export const workOrders = workOrderReducers.list;
export const workOrdersForm = workOrderReducers.form;

const tagReducers = createCrudReducers('TAG');
export const tags = tagReducers.list;
export const tagsForm = tagReducers.form;

const dosificationReducers = createCrudReducers('DOSIFICATION');
export const dosifications = dosificationReducers.list;
export const dosificationsForm = dosificationReducers.form;

const reasonReducers = createCrudReducers('REASON');
export const reasons = reasonReducers.list;
export const reasonsForm = reasonReducers.form;

const epiReducers = createCrudReducers('EPI');
export const epis = epiReducers.list;
export const episForm = epiReducers.form;

const rawReducers = createCrudReducers('RAW');
export const raws = rawReducers.list;
export const rawsForm = rawReducers.form;

const roleReducers = createCrudReducers('ROLE');
export const roles = roleReducers.list;
export const rolesForm = roleReducers.form;

const leaveReducers = createCrudReducers('LEAVE');
export const leaves = leaveReducers.list;
export const leavesForm = leaveReducers.form;

const dosificationProductReducers = createCrudReducers('DOSIFICATIONPRODUCT');
export const dosificationProducts = dosificationProductReducers.list;
export const dosificationProductsForm = dosificationProductReducers.form;

const dosificationConfigReducers = createCrudReducers('DOSIFICATIONCONFIG');
export const dosificationConfigs = dosificationConfigReducers.list;
export const dosificationConfigsForm = dosificationConfigReducers.form;

const opcuaReducers = createCrudReducers('OPCUA');
export const OPCUA = opcuaReducers.list;
export const OPCUAForm = opcuaReducers.form;

const expenseReducers = createCrudReducers('EXPENSE');
export const expenses = expenseReducers.list;
export const expensesForm = expenseReducers.form;

const imputHourReducers = createCrudReducers('IMPUTHOUR');
export const imputHours = imputHourReducers.list;
export const imputHoursForm = imputHourReducers.form;

const timeRequestReducers = createCrudReducers('TIMEREQUEST');
export const timeRequests = timeRequestReducers.list;
export const timeRequestsForm = timeRequestReducers.form;

const holidayRequestReducers = createCrudReducers('HOLIDAYREQUEST');
export const holidayRequests = holidayRequestReducers.list;
export const holidayRequestsForm = holidayRequestReducers.form;

const alertReducers = createCrudReducers('ALERT');
export const alerts = alertReducers.list;
export const alertsForm = alertReducers.form;

const stockReducers = createCrudReducers('STOCK');
export const stocks = stockReducers.list;
export const stocksForm = stockReducers.form;

const dosistatReducers = createCrudReducers('DOSISTAT');
export const dosistats = dosistatReducers.list;
export const dosistatsForm = dosistatReducers.form;

const taskReducers = createCrudReducers('TASK');
export const tasks = taskReducers.list;
export const tasksForm = taskReducers.form;

const permissionReducers = createCrudReducers('PERMISSION');
export const permissions = permissionReducers.list;
export const permissionsForm = permissionReducers.form;

const eventReducers = createCrudReducers('EVENT');
export const events = eventReducers.list;
export const eventsForm = eventReducers.form;

const procedimentReducers = createCrudReducers('PROCEDIMENT');
export const procediments = procedimentReducers.list;
export const procedimentsForm = procedimentReducers.form;

const oficialDocumentReducers = createCrudReducers('OFICIALDOCUMENT');
export const oficialDocuments = oficialDocumentReducers.list;
export const oficialDocumentsForm = oficialDocumentReducers.form;

const purchasesReducers = createCrudReducers('PURCHASES');
export const purchasess = purchasesReducers.list;
export const purchasessForm = purchasesReducers.form;
