import configuration from "../assets/menuImages/Configuración.png";
import buys from "../assets/menuImages/Compras.png";
import security from "../assets/menuImages/Ficha-tecnica.png";
import instalation from "../assets/menuImages/Instalaciones.png";
import incidents from "../assets/menuImages/Incidencias.png";
import shopCar from "../assets/menuImages/Carrito.png";
import satisfactions from "../assets/menuImages/Satisfacciones.png";
import users from "../assets/menuImages/Usuarios.png";
import equipment from "../assets/menuImages/Equipos.png";
import projects from "../assets/menuImages/Proyectos.png";
import singUp from "../assets/menuImages/Fichar.png";
import business from "../assets/menuImages/Control-accesos.png";
import invoice from "../assets/menuImages/Facturas-Albaranes.png";
import deliveryNote from "../assets/menuImages/Albaranes.png";
import calendario from "../assets/menuImages/Sugerencias.png";
import beneficios from "../assets/menuImages/estadisticas.png";
import download from "../assets/menuImages/Descarga.png";
import camionReparto from "../assets/menuImages/Repartos.png";
import buzon from "../assets/menuImages/Sugerencias.png";
import gastos from "../assets/menuImages/gastos.png";
import tags from "../assets/menuImages/Tags-Gastos.png";
import reasons from "../assets/menuImages/Motivos.png";
import permiso from "../assets/menuImages/Roles.png";
// import conexion from "../assets/menuImages/Opcua.png";
import vacaciones from "../assets/menuImages/Vacaciones.png";
import equipo from "../assets/menuImages/Rrhh.png";
import cambio from "../assets/menuImages/Peticiones.png";
import clock from "../assets/menuImages/Bajas.png";
import productos from "../assets/menuImages/Dosificacion.png";
import map from "../assets/menuImages/mapa.png";
import firmar from "../assets/menuImages/Partes.png";
import produccion from "../assets/menuImages/Dosificacion.png";
import construccion from "../assets/menuImages/Materias-primas.png";
import casco from "../assets/menuImages/Epis-materias.png";
import lavnet from "../assets/menuImages/LAVNET.png";
import dosilav from "../assets/menuImages/logoDosilav1.png";
import stock from "../assets/menuImages/Stock.png";
import statsquestion from "../assets/menuImages/Estadisticas-encuestas.png";
import tasks from "../assets/menuImages/Tareas.png";
import tasksList from "../assets/menuImages/Lista-Tareas.png";
import hoursControl from "../assets/menuImages/controlHorario.png";
import datanexus from "../assets/menuImages/datanexus.png";
import notifier from "../assets/menuImages/notifier.png";

export const PRINCIPALMENU = [
	{
		keyApp: "configuration",
		navigation: "configuration",
		title: "Configuración",
		subtitle: "Usuarios, roles",
		image: `url(${configuration})`,
		hasChildren: true,
		showInMenu: true,
	},
	{
		keyApp: "instalationsMenu",
		navigation: "instalations",
		title: "Instalación",
		subtitle: "Instalaciones y equipos",
		image: `url(${instalation})`,
		hasChildren: true,
		showInMenu: true,
	},
	{
		keyApp: "incidencesMenu",
		navigation: "incidences",
		title: "Incidencias",
		subtitle: "Tus incidencias y partes de trabajo",
		image: `url(${incidents})`,
		hasChildren: true,
		showInMenu: true,
	},
	{
		keyApp: "shopCarts",
		navigation: "shopCarts",
		title: "Compras",
		subtitle: "Carritos y Comprar",
		image: `url(${buys})`,
		hasChildren: true,
		showInMenu: true,
	},
	{
		keyApp: "satisfactions",
		navigation: "satisfactions",
		title: "Satisfacciones",
		subtitle: "Satisfacciones sobre las incidencias",
		image: `url(${satisfactions})`,
		showInMenu: true,
	},
	{
		keyApp: "stadistics",
		navigation: "stadistics",
		title: "Estadísticas",
		subtitle: "Estadísticas sobre la aplicación",
		image: `url(${beneficios})`,
		showInMenu: true,
	},
	{
		keyApp: "menuProjects",
		navigation: "menuProjects",
		title: "Gestión de proyectos",
		subtitle: "Proyectos y diagrama de gantt",
		image: `url(${projects})`,
		hasChildren: true,
		showInMenu: true,
	},
	{
		keyApp: "singUp",
		navigation: "singUp",
		title: "Fichar",
		subtitle: "Fichar entradas y salidas",
		image: `url(${singUp})`,
		showInMenu: true,
	},
	{
		keyApp: "singUpsMenu",
		navigation: "singUps",
		title: "Accesos",
		subtitle: "Ver todos los accesos",
		image: `url(${business})`,
		hasChildren: true,
		showInMenu: true,
	},
	{
		keyApp: "RRHH",
		navigation: "RRHH",
		title: "RRHH",
		subtitle: "Recursos humanos",
		image: `url(${equipo})`,
		hasChildren: true,
		showInMenu: true,
	},
	{
		keyApp: "dosification",
		navigation: "dosification",
		title: "Dosificación",
		subtitle: "Productos y dosificaciones",
		image: `url(${productos})`,
		showInMenu: true,
		hasChildren: true,
	},
	{
		keyApp: "invoicesDeliveryNotes",
		navigation: "allInvoicesDeliveryNotes",
		title: "Documentos",
		subtitle: "Descargar las facturas y albaranes de este año",
		image: `url(${invoice})`,
		hasChildren: true,
		showInMenu: true,
	},
	{
		keyApp: "episMenu",
		navigation: "epis",
		title: "EPIS",
		subtitle: "Control de EPIS y materias primas",
		image: `url(${casco})`,
		showInMenu: true,
		hasChildren: true,
	},
	{
		keyApp: "repartiments",
		navigation: "repartiments",
		title: "Repartos",
		subtitle: "Ver todos los albaranes de hoy",
		image: `url(${camionReparto})`,
		showInMenu: true,
	},
	{
		keyApp: "suggestion",
		navigation: "suggestion",
		title: "Sugerencias",
		subtitle: "Introduce errores o sugerencias",
		image: `url(${buzon})`,
		showInMenu: true,
	},
	{
		keyApp: "expenses",
		navigation: "expenses",
		title: "Gastos",
		subtitle: "Introduce gastos",
		image: `url(${gastos})`,
		showInMenu: true,
	},
	{
		keyApp: "procediments",
		navigation: "procediments",
		title: "Procedimientos",
		subtitle: "Introduce gastos",
		image: `url(${gastos})`,
		showInMenu: true,
	},
	{
		keyApp: "datanexusapp",
		navigation: "datanexusapp",
		title: "APP DataNexus",
		subtitle: "Acceso directo a la app de DataNexus",
		image: `url(${datanexus})`,
		showInMenu: true,
		action: "https://datanexus.tipdata.es/",
	},
	{
		keyApp: "notifierapp",
		navigation: "notifierapp",
		title: "APP Notifier",
		subtitle: "Acceso directo a la app de Notifier",
		image: `url(${notifier})`,
		showInMenu: true,
		action: "https://notifier.tipdata.es/",
	},
	// {
	//   keyApp: "opcua",
	//   navigation: "opcua",
	//   title: "OPCUA",
	//   subtitle: "Introduce la configuración del OPCUA",
	//   image: `url(${conexion})`,
	//   showInMenu: true,
	// },
];

export const CONFIGURATIONMENU = [
	{
		keyApp: "users",
		navigation: "configuration/users",
		title: "Usuarios",
		subtitle: "Todos los usuarios",
		image: `url(${users})`,
		showInMenu: true,
	},
	{
		keyApp: "downloadDocuments",
		navigation: "configuration/downloadDocument",
		title: "Descargas",
		subtitle: "Ver todas las descargas de documentos",
		image: `url(${download})`,
		showInMenu: true,
	},
	{
		keyApp: "tags",
		navigation: "configuration/tags",
		title: "Tags",
		subtitle: "Ver todas las tags de gastos",
		image: `url(${tags})`,
		showInMenu: true,
	},
	{
		keyApp: "reasons",
		navigation: "configuration/reasons",
		title: "Motivos",
		subtitle: "Ver todos los motivos de horas extras",
		image: `url(${reasons})`,
		showInMenu: true,
	},
	{
		keyApp: "roles",
		navigation: "configuration/roles",
		title: "Roles",
		subtitle: "Ver todos los roles",
		image: `url(${permiso})`,
		showInMenu: true,
	},
];

export const PROJECTSMENU = [
	// {
	// 	keyApp: "projects",
	// 	navigation: "menuProjects/projects",
	// 	title: "Proyectos",
	// 	subtitle: "Todos los proyectos",
	// 	image: `url(${projects})`,
	// 	showInMenu: true,
	// },
	{
		keyApp: "tasks",
		navigation: "menuProjects/tasks",
		title: "Tareas",
		subtitle: "Tareas",
		image: `url(${tasks})`,
		showInMenu: true,
	},
	{
		keyApp: "listtasks",
		navigation: "menuProjects/listtasks",
		title: "Lista de tareas",
		subtitle: "Tareas",
		image: `url(${tasksList})`,
		showInMenu: true,
	},
	{
		keyApp: "hoursControl",
		navigation: "menuProjects/hoursControl",
		title: "Control de horas",
		subtitle: "Control de horas",
		image: `url(${hoursControl})`,
		showInMenu: true,
	},
];

export const SHOPMENU = [
	{
		keyApp: "shopCarts",
		navigation: "shopCarts/carts",
		title: "Carritos",
		subtitle: "Todos los carritos",
		image: `url(${shopCar})`,
		showInMenu: true,
	},
	{
		keyApp: "buy",
		navigation: "shopCarts/buy",
		title: "Comprar",
		subtitle: "Realiza compras",
		image: `url(${buys})`,
		showInMenu: true,
	},
	{
		keyApp: "purchasess",
		navigation: "shopCarts/purchasess",
		title: "Compras a proveedores",
		subtitle: "Compras a proveedores",
		image: `url(${buys})`,
		showInMenu: true,
	},
	{
		keyApp: "securitySheets",
		navigation: "shopCarts/security",
		title: "Fichas",
		subtitle: "Ver todas las fichas técnicas descargadas",
		image: `url(${security})`,
		showInMenu: true,
	},
];

export const INSTALATIONMENU = [
	{
		keyApp: "instalations",
		navigation: "instalations/instalation",
		title: "Instalaciones",
		subtitle: "Tus instalaciones",
		image: `url(${instalation})`,
		showInMenu: true,
	},
	{
		keyApp: "equipments",
		navigation: "instalations/equipment",
		title: "Equipos",
		subtitle: "Tus equipos",
		image: `url(${equipment})`,
		showInMenu: true,
	},
];

export const INCIDENCESMENU = [
	{
		keyApp: "incidences",
		navigation: "incidences/incidences",
		title: "Incidencias",
		subtitle: "Tus incidencias",
		image: `url(${incidents})`,
		showInMenu: true,
	},
	{
		keyApp: "workOrders",
		navigation: "incidences/workOrders",
		title: "Partes",
		subtitle: "Tus partes de trabajo",
		image: `url(${firmar})`,
		showInMenu: true,
	},
];

export const INVOICESMENU = [
	{
		keyApp: "invoices",
		navigation: "allInvoicesDeliveryNotes/invoices",
		title: "Facturas",
		subtitle: "Tus facturas",
		image: `url(${invoice})`,
		showInMenu: true,
	},
	{
		keyApp: "deliveryNotes",
		navigation: "allInvoicesDeliveryNotes/deliveryNotes",
		title: "Albaranes",
		subtitle: "Tus albaranes",
		image: `url(${deliveryNote})`,
		showInMenu: true,
	},
	{
		keyApp: "security",
		navigation: "allInvoicesDeliveryNotes/security",
		title: "Fichas",
		subtitle: "Tus albaranes",
		image: `url(${deliveryNote})`,
		showInMenu: true,
	},
];

export const SINGUPS = [
	{
		keyApp: "singUps",
		navigation: "singUps/singUpsList",
		title: "Accesos",
		subtitle: "Ver todos los accesos",
		image: `url(${business})`,
		showInMenu: true,
	},
	{
		keyApp: "timeRequests",
		navigation: "singUps/timeRequest",
		title: "Peticiones",
		subtitle: "Peticiones de cambio de fecha",
		image: `url(${deliveryNote})`,
		showInMenu: true,
	},
	{
		keyApp: "map",
		navigation: "singUps/map",
		title: "Mapa",
		subtitle: "Mapa con los fichajes",
		image: `url(${map})`,
		showInMenu: true,
	},
];

export const RRHH = [
	{
		keyApp: "mySingUp",
		navigation: "RRHH/mySingUp",
		title: "Mis accesos",
		subtitle: "Consultar mis salidas y entradas",
		image: `url(${calendario})`,
		showInMenu: true,
	},
	{
		keyApp: "holidayRequests",
		navigation: "RRHH/holidayRequest",
		title: "Vacaciones",
		subtitle: "Peticiones de vacaciones y bajas",
		image: `url(${vacaciones})`,
		showInMenu: true,
	},
	{
		keyApp: "workShifts",
		navigation: "RRHH/workShift",
		title: "Turnos",
		subtitle: "Turnos de trabajo",
		image: `url(${cambio})`,
		showInMenu: true,
	},
	{
		keyApp: "workShiftMaintenance",
		navigation: "RRHH/workShiftMaintenance",
		title: "Turnos mantenimiento",
		subtitle: "Turnos de trabajo",
		image: `url(${cambio})`,
		showInMenu: true,
	},
	{
		keyApp: "leaves",
		navigation: "RRHH/leave",
		title: "Bajas",
		subtitle: "Bajas de trabajo",
		image: `url(${clock})`,
		showInMenu: true,
	},
	{
		keyApp: "permissions",
		navigation: "RRHH/permission",
		title: "Permisos",
		subtitle: "Motivos de ausencia",
		image: `url(${clock})`,
		showInMenu: true,
	},
	{
		keyApp: "events",
		navigation: "RRHH/events",
		title: "Eventos",
		subtitle: "Eventos",
		image: `url(${clock})`,
		showInMenu: true,
	},
	{
		keyApp: "oficialDocuments",
		navigation: "RRHH/oficialDocuments",
		title: "Documentos",
		subtitle: "Eventos",
		image: `url(${clock})`,
		showInMenu: true,
	},
];

export const DOSIFICATION = [
	{
		keyApp: "lavnetremote",
		navigation: "dosification/lavnet",
		title: "LavNet Remote",
		subtitle: "Acceso directo a LavNet Remote",
		image: `url(${lavnet})`,
		showInMenu: true,
		action: "https://www.lavnetremote.com/login",
	},
	{
		keyApp: "laundryapp",
		navigation: "dosification/laundryapp",
		title: "APP Lavanderías",
		subtitle: "Acceso directo a la app de lavanderias",
		image: `url(${dosilav})`,
		showInMenu: true,
		action: "https://dosilav-app.deploy.ecoquimic.com/",
	},
	{
		keyApp: "dosification",
		navigation: "dosification/dosification",
		title: "Productos",
		subtitle: "Productos y configuración",
		image: `url(${productos})`,
		showInMenu: true,
	},
	{
		keyApp: "dosificationProducts",
		navigation: "dosification/dosificationProducts",
		title: "Historial Ventas",
		subtitle: "Dosificaciones",
		image: `url(${produccion})`,
		showInMenu: true,
	},
	{
		keyApp: "stock",
		navigation: "dosification/stocks",
		title: "Stock",
		subtitle: "Stock de los productos",
		image: `url(${stock})`,
		showInMenu: true,
	},
	{
		keyApp: "dosificationConfig",
		navigation: "dosification/dosificationConfigs",
		title: "Configuración",
		subtitle: "Configuracion de los productos",
		image: `url(${configuration})`,
		showInMenu: true,
	},
	{
		keyApp: "dosificationStadistics",
		navigation: "dosification/dosificationStadistics",
		title: "Estadísticas",
		subtitle: "Estadísticas de los productos",
		image: `url(${beneficios})`,
		showInMenu: true,
	},
	{
		keyApp: "dosistats",
		navigation: "dosification/dosistats",
		title: "Encuestas",
		subtitle: "Estadísticas de las encuestas",
		image: `url(${statsquestion})`,
		showInMenu: true,
	},
];

export const EPIS = [
	{
		keyApp: "epis",
		navigation: "epis/epis",
		title: "EPIS",
		subtitle: "Control de EPIS",
		image: `url(${casco})`,
		showInMenu: true,
	},
	{
		keyApp: "raws",
		navigation: "epis/raw",
		title: "Materias",
		subtitle: "Materias primas",
		image: `url(${construccion})`,
		showInMenu: true,
	},
];

export const BASICMENU = {
	configuration: CONFIGURATIONMENU,
	shopCarts: SHOPMENU,
	instalationsMenu: INSTALATIONMENU,
	menuProjects: PROJECTSMENU,
	singUpsMenu: SINGUPS,
	RRHH,
	invoicesDeliveryNotes: INVOICESMENU,
	incidencesMenu: INCIDENCESMENU,
	dosification: DOSIFICATION,
	episMenu: EPIS,
};

export const ALLMENU = Array.prototype.concat(
	PRINCIPALMENU,
	CONFIGURATIONMENU,
	SHOPMENU,
	INSTALATIONMENU,
	PROJECTSMENU,
	SINGUPS,
	RRHH,
	INVOICESMENU,
	INCIDENCESMENU,
	EPIS,
	DOSIFICATION
);
